import { FC } from 'react';

import { useMapContext } from '@/components/features/map/MapContext';
import {
	filterActiveMuseums,
	filterExhibitionMuseumsWithLocation,
	getBreakupLocation,
} from '@/components/shared/map-stats/MapStats.utils';
import { MapFocusTypes } from '@/enums/MuseumFilterTypes';
import classNames from 'classnames';
import styles from './MapStats.module.scss';
import MapStatsItem from './MapStatsItem';
import { MuseumCollectionDocument } from '@/interfaces/IMuseumResource';
import { IStoryResource } from '@/interfaces/IStoryResource';
import { CollectionDocument } from '@/interfaces/Jsonapi';
import { IClusterResource } from '@/interfaces/IClusterResource';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

export interface IMapStatsProps {
	museums?: MuseumCollectionDocument['data'];
	stories?: CollectionDocument<IStoryResource>;
	clusters?: Array<IClusterResource>;
	className?: string;
}

export const MapStats: FC<IMapStatsProps> = (props) => {
	const { museums, stories, clusters } = props;
	const router = useRouter();
	const { activeItem, setActiveItem } = useMapContext();
	const { t } = useTranslation();

	const activeMuseums = filterActiveMuseums(museums);
	const currentExhibitions = filterExhibitionMuseumsWithLocation(museums);

	const mapStatsClassNames = classNames(styles.mapStats, props.className);

	function onMuseumsStatsClick() {
		setActiveItem(null);
		router.push('/visit');
	}

	function onExhibitsStatsClick() {
		setActiveItem(null);
		router.push('/explore');
	}

	const openModal = (modalName) => {
		// Locates the cluster with most breakups as default location
		const densestClusterLocation = getBreakupLocation(clusters);
		router.replace({ query: { open: modalName, ...densestClusterLocation } });
	};

	return (
		<div className={mapStatsClassNames}>
			<MapStatsItem
				id={MapFocusTypes.MUSEUMS}
				label={t('locations')}
				count={activeMuseums.length}
				loaded={true}
				icon="assets/icons/poi-museum.svg"
				onClick={onMuseumsStatsClick}
				onMouseEnter={() => setActiveItem(MapFocusTypes.MUSEUMS)}
				onMouseLeave={() => setActiveItem(null)}
				disabled={activeItem && activeItem !== MapFocusTypes.MUSEUMS}
			/>
			<MapStatsItem
				id={MapFocusTypes.EXHIBITS}
				label={t('exhibitions')}
				count={currentExhibitions.length}
				loaded={true}
				icon="assets/icons/poi-exhibition-current.svg"
				onClick={onExhibitsStatsClick}
				onMouseEnter={() => setActiveItem(MapFocusTypes.EXHIBITS)}
				onMouseLeave={() => setActiveItem(null)}
				disabled={activeItem && activeItem !== MapFocusTypes.EXHIBITS}
			/>
			<MapStatsItem
				id={MapFocusTypes.CLUSTERS}
				label={t('breakup-pins')}
				count={stories?.meta?.totalCount}
				loaded={true}
				icon="/assets/icons/poi-break-up.svg"
				onClick={() => openModal('breakups-map')}
				onMouseEnter={() => setActiveItem(MapFocusTypes.CLUSTERS)}
				onMouseLeave={() => setActiveItem(null)}
				disabled={activeItem && activeItem !== MapFocusTypes.CLUSTERS}
			/>
		</div>
	);
};
