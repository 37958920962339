import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import styles from './PinnedNews.module.scss';

import { SOCIAL_LINKS } from '@/utils/constants';
import { SocialLinks } from '@/components/shared/links/SocialLinks/SocialLinks';
import SocialBar from '@/components/features/posts/SocialBar/SocialBar';
import ImageLoader from '@/components/features/posts/ImageLoader/ImageLoader';
import { getImageUrl, size } from '@/utils/attachment';
import { formatPostedAtDate } from '@/utils/post-date';
import { ellipsis, escapeSymbols } from '@/utils/string-util';
import { LikeButton } from '@/components/features/posts/SocialBar/LikeButton/LikeButton';
import { getCollection } from '@/utils/social-media-share-links';
import { useRouter } from 'next/router';
import { INewsResource } from '@/interfaces/INewsResource';
import { useUser } from '@/hooks/useUser';

export interface IPinnedNewsProps {
	news: Array<INewsResource>;
}

export const PinnedNews: FC<IPinnedNewsProps> = ({ news }) => {
	const { t } = useTranslation('common');
	const router = useRouter();
	const { user } = useUser();

	const onOpenFeed = (item) => {
		router.push(`/feed/${item.slug}`);
	};

	return (
		<div className={styles.pinnedNews}>
			<div className={styles.pinnedNewsHeader}>
				<h2 className={styles.pinnedNewsHeaderTitle}>{t('pinned-news')}</h2>
				<div className={styles.pinnedNewsHeaderActions}>
					<SocialLinks links={SOCIAL_LINKS} />
				</div>
			</div>
			<div className={styles.pinnedNewsContent}>
				{news.map((item) => {
					return (
						<div key={item.id} className={styles.pinnedNewsItem}>
							<div className={styles.pinnedNewsImg}>
								<ImageLoader
									src={getImageUrl(item.image, size.LARGE)}
									preloader={getImageUrl(item.image, size.PRELOADER)}
									className={styles.pinnedNewsImgLoader}
									imageClassName={styles.pinnedNewsImgLoaderImg}
								/>
							</div>
							<div className={styles.pinnedNewsBox}>
								<div className={styles.pinnedNewsBoxWrapper}>
									<div className={styles.pinnedNewsBoxContent}>
										<span onClick={() => onOpenFeed(item)} className={styles.pinnedNewsBoxLink}>
											<h3 className={styles.pinnedNewsBoxTitle}>{item.title}</h3>
										</span>
										<div className={styles.pinnedNewsBoxSubtitle}>
											<div className={styles.pinnedNewsBoxSubtitleRow}>{formatPostedAtDate(item.postedAt)}</div>
											<div className={styles.pinnedNewsBoxSubtitleRow}>{item.location}</div>
										</div>
										<div className={styles.pinnedNewsBoxDescription}>{ellipsis(escapeSymbols(item.content))}</div>
									</div>
									<div className={styles.pinnedNewsBoxFooter}>
										{user ? <LikeButton faveable={item} inverted /> : null}
										<SocialBar inverse noSkew shareLinksCollection={getCollection('/feed/' + item.slug, item)} />
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
