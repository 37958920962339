import { Story } from '@/analytics/ga';
import GA from '@/analytics/ga/components/GA';
import Button from '@/components/core/Button/Button';
import Heading from '@/components/core/Heading/Heading';
import AddPost from '@/components/features/posts/AddPost/AddPost';
import Filter from '@/components/features/posts/Filter/Filter';
import PostItem from '@/components/features/posts/PostItem/PostItem';
import PostsContainer from '@/components/features/posts/PostsContainer/PostsContainer';
import { IPostResource, IPostTopLevelMeta } from '@/interfaces/IPostResource';

import { slugify } from '@/utils/slug';
import { withTranslation, WithTranslation } from 'next-i18next';
import { NextRouter, withRouter } from 'next/router';
import { Component } from 'react';

import styles from './HomePosts.module.scss';

interface WithRouterProps {
	router: NextRouter;
}

interface IHomePostsProps extends WithTranslation, WithRouterProps {
	posts: Array<IPostResource>;
	meta: IPostTopLevelMeta;
	onLoadMore: () => void;
	hasMore: boolean;
	loading: boolean;
}

const getSlug = (item: IPostResource) => {
	return item.slug || slugify(item);
};

const getPostItems = (data: Array<IPostResource>) => {
	return data.map((item) => {
		const to = '/feed/' + getSlug(item);

		return <PostItem data={item} key={item.id} withActions to={to} />;
	});
};

class HomePosts extends Component<IHomePostsProps> {
	render() {
		const { posts, t, router, onLoadMore, meta, hasMore, loading } = this.props;

		const onFilterChange = (filter) => {
			if (filter) {
				router.replace({ query: { filter: filter } }, undefined, { scroll: false });
			}
		};

		const filterOrderer = (filters) => {
			const filterOrdering = ['trending', 'news', 'collection', 'stories'];

			return filters.sort((fst, snd) => filterOrdering.indexOf(fst) - filterOrdering.indexOf(snd));
		};

		const filterNames = {
			trending: 'trending',
			news: 'news',
			collection: 'exhibits',
			stories: 'stories',
		};

		const pinItemText = 'pinshare-a-story';

		const pin = (
			<GA event={Story.addStory} key={'pin'}>
				<AddPost text={pinItemText} icon="/assets/icons/heart-broken.svg" to="share-story" />
			</GA>
		);

		return (
			<div className={styles.posts}>
				<Heading text={`${t('everything')} (${meta.totalCount})`}>
					<Filter
						onFilterChange={onFilterChange}
						collection={meta.filters}
						order={filterOrderer}
						labels={filterNames}
					/>
				</Heading>
				<div className={styles.postsContent}>
					<PostsContainer>{[pin, ...getPostItems(posts)]}</PostsContainer>
				</div>
				{hasMore ? (
					<div className={styles.postsAction}>
						<Button onClick={onLoadMore} color={Button.color.PRIMARY} size={Button.size.BIG} loading={loading}>
							{t('load-more')}
						</Button>
					</div>
				) : null}
				{hasMore ? <p className={styles.panelSeparator}>{t('or')}</p> : null}
			</div>
		);
	}
}

export default withTranslation()(withRouter(HomePosts));
