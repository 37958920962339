export function deslugify(id: string) {
	return id
		.split('--')
		.map((word) => decodeURIComponent(word))
		.pop();
}

export function slugify({ title = 'details', id }, { encode = true } = {}) {
	const titleSlug = (title || 'details')
		.toLowerCase()
		.replace('-', '_')
		.replace("'", '')
		.split(/\s+/g)
		.filter((word) => word.length > 0)
		.map((word) => (encode ? encodeURIComponent(word) : word))
		.join('-');

	return `${titleSlug}--${id}`;
}
