import React, { FC } from 'react';

import styles from './SocialLinks.module.scss';
import { useTranslation } from 'next-i18next';

export interface ISocialLinksProps {
	links: Array<{ id: string; link: string }>;
}

export const SocialLinks: FC<ISocialLinksProps> = ({ links }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.SocialLinks}>
			<div className={styles.SocialLinksLabel}>{t('follow-us')}</div>
			<ul className={styles.SocialLinksContent}>
				{links.map((item) => {
					return (
						<li className={styles.SocialLinksItem} key={`${item.id}-pinned-news`}>
							<a href={item.link} target="_blank" className={styles.SocialLinksLink}>
								<img alt={item.id} src={`/assets/svgs/ic-${item.id}.svg`} />
							</a>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
